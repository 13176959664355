import theme from '@/theme'

export default {
    myGrid: {
        fontFamily: theme.typography.fontFamily,
        '& .MuiDataGrid-columnSeparator--sideRight': { display: 'none' },
        '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: theme.typography.fontWeightBold,
        },
        '& .MuiDataGrid-main > div:nth-of-type(2)': {
            height: 'fit-content !important',
        },
        '& .MuiDataGrid-main > div': {
            height: 'fit-content !important',
        },
        // make row odd has grey color
        '& .MuiDataGrid-row:nth-of-type(odd)': {
            backgroundColor: theme.colors.lightGrey1,
        },
        '& .MuiDataGrid-row:hover': {
            backgroundColor: 'transparent',
        },
        '& .MuiDataGrid-row:nth-of-type(odd):hover': {
            backgroundColor: theme.colors.lightGrey1,
        },
        //remove outline header
        '& .MuiDataGrid-columnHeader': { outline: 'unset' },
        '& .MuiDataGrid-columnHeader:focus-within': { outline: 'unset' },
        //remove outline cell
        '& .MuiDataGrid-cell:focus-within': { outline: 'none' },
        '& .MuiDataGrid-cell:focus': { outline: 'unset' },
    },
}
